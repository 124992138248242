import React, { useContext, useEffect, useState } from "react";
import { Button } from "@chakra-ui/button";
import { UnlockIcon } from "@chakra-ui/icons";
import { Stack } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import axios from "axios";

import { CORE_API_URL } from "../../config";
import { Divider } from "../components/atoms/divider/divider";
import { Card } from "../components/organisms/card/card";
import { Layout } from "../components/organisms/layout/layout";
import Sidebar from "../components/organisms/sidebar/sidebar";
import { AppContext } from "../context/app-context";
import { useToast } from "@chakra-ui/toast";
import { DeriveError } from "../utils/derive-error";
import { StatWithIndicator } from "../components/organisms/stat-with-indicator/stat-with-indicator";
import { InvoicesTable } from "../components/organisms/invoices-table/invoices-table";
import { navigate } from "gatsby-link";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";
// markup
const IndexPage = () => {
  React.useEffect(() => {}, []);
  const context = useContext(AppContext);
  const { user, setUser } = context;
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unsents, setUnsents] = useState({
    opens: [],
    drafts: [],
    paids: [],
  });
  const [sendInvoiceLoading, setSendInvoiceLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    mixpanel.init("2c699372d52051ce0721290d82e9c671", { debug: true });

    const apiCall = async () => {
      try {
        setLoading(true);
        // query user first
        let response;
        let userCopy = { ...user };
        /* if (!userCopy.notionCredential) {
          response = await axios.post(
            `${CORE_API_URL}/get_refreshed_user`,
            {
              user,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          userCopy = response.data.user;
          setUser(userCopy);
        } */
        console.log(userCopy);
        response = await axios.post(
          `${CORE_API_URL}/get_user_invoices`,
          {
            user: userCopy,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setInvoices(response.data.invoices);
        setUser({ ...user, ...response.data.user });
        console.log(response.data);
      } catch (error) {
        const err = DeriveError(error);
        toast({
          title: "Invoice query error.",
          description: err,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        if (error && error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          return;
        } else if (!user) {
          navigate("/sign-in");
        } else if (!user.notionCredential) {
          window.location.href = `${CORE_API_URL}/install?email=${user.email}`;
        }
      } finally {
        setLoading(false);
      }
    };
    if (!sendInvoiceLoading) apiCall();
  }, [sendInvoiceLoading]);

  useEffect(() => {
    const apiCall = async () => {
      try {
        let response;

        response = await axios.post(
          `${CORE_API_URL}/get_unsent_open_invoices`,
          {
            user,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        const { drafts, opens, paids } = response.data;
        setUnsents({
          drafts,
          opens,
          paids,
        });
      } catch (error) {
        alert(error.message);
      }
    };

    if (!sendInvoiceLoading) apiCall();
  }, [sendInvoiceLoading]);
  if (!user) return <></>;
  const { opens, drafts, paids } = unsents;
  console.log(context);
  return (
    <Layout>
      <Sidebar>
        {loading ? (
          <Stack>
            <Skeleton height="200px" />
            <Skeleton height="200px" />
            <Skeleton height="200px" />
          </Stack>
        ) : (
          <>
            <Alert
              onMouseOver={() => {
                mixpanel.track(
                  "Hovered over draft and open invoice alert on dashboard"
                );
              }}
              status="info"
              className="mb-4"
            >
              <AlertIcon />
              <AlertTitle mr={2}>
                {drafts.length} Invoices in draft <br />
                {opens.length} Open Invoices in send queue <br />
                {paids.length} Paid Invoices in send queue <br />
                <Button
                  className="mt-2"
                  isLoading={sendInvoiceLoading}
                  onClick={async () => {
                    try {
                      setSendInvoiceLoading(true);
                      const response = await axios.post(
                        `${CORE_API_URL}/trigger_invoices_in_notion`,
                        {
                          user,
                        },
                        {
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                          },
                        }
                      );
                      console.log(response.status);
                      //window.location.reload(1);
                    } catch (error) {
                      alert(error.message);
                    } finally {
                      setSendInvoiceLoading(false);
                    }
                  }}
                >
                  Send All Invoices In Queue
                </Button>
              </AlertTitle>
              <AlertDescription></AlertDescription>
            </Alert>
            <div
              onMouseOver={() => {
                mixpanel.track("Hovered over invoice stats on dashboard");
              }}
            >
              <StatWithIndicator invoices={invoices} />
            </div>
            <InvoicesTable invoices={invoices} />
          </>
        )}
      </Sidebar>
    </Layout>
  );
};

export default IndexPage;
/**
              <CloseButton position="absolute" right="8px" top="8px" />
 * 
 */
