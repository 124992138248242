import React, { useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
} from "@chakra-ui/react";
import { handleRounding } from "../stat-with-indicator/stat-with-indicator";
import mixpanel from "mixpanel-browser";
const badgeMap = {
  paid: <Badge colorScheme="green">paid</Badge>,
  open: <Badge colorScheme="blue">open</Badge>,
  uncollectable: <Badge colorScheme="red">uncollectable</Badge>,
  voided: <Badge colorScheme="grey">void</Badge>,
};
export const InvoicesTable = ({ invoices = [] }) => {
  useEffect(() => {
    // user signs up with email and password
    // i generate a license key for this user
    // in the database when the user pays i will flip a boolean in the db that says this user has paid
    // that license key will allow user ot use payment links and branded invoices
    // query all users invoice by license key
  }, []);
  return (
    <div
      onMouseOver={() => {
        mixpanel.track("Hovered over invoices table on dashboard");
      }}
    >
      <div>
        <Table
          justifyContent="space-evenly"
          bg="white"
          variant="simple"
          mt="12"
        >
          <TableCaption>&copy; Botion 2022</TableCaption>
          <Thead>
            <Tr>
              <Th>Invoice Number</Th>
              <Th>Status</Th>
              <Th>Amount</Th>
              <Th display={["none", "block", "block"]}>Last Updated</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoices.map((invoice) => {
              const { data, invoiceStatus, updatedAt } = invoice;
              const { Invoice_No, Total } = data;
              return (
                <Tr
                  onMouseOver={() => {
                    mixpanel.track("Hovered over an invoice on dashboard");
                  }}
                  onClick={() => {
                    mixpanel.track("Clicked an invoice on dashboard");
                  }}
                >
                  <Td>{Invoice_No}</Td>

                  <Td>{badgeMap[invoiceStatus]}</Td>
                  <Td>${handleRounding(Total)}</Td>
                  <Td display={["none", "block", "block"]}>
                    {new Intl.DateTimeFormat("en-US").format(
                      new Date(updatedAt)
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};
