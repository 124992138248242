import React from "react";

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Box,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

interface Props {
  statLabelOne: string;
  statNumberOne: string;
  statHelpTextOne: string;
  stateArrowTypeOne: "increase" | "decrease";
  statLabelTwo: string;
  statNumberTwo: string;
  statHelpTextTwo: string;
  stateArrowTypeTwo: "increase" | "decrease";
}
export const handleRounding = (number) => {
  return Math.round(number);
};
export const StatWithIndicator = ({ invoices = [] }) => {
  const total = invoices.length;
  console.log(invoices);
  const openCount = invoices.filter(
    (invoice) => invoice.invoiceStatus === "open"
  ).length;
  const paidCount = invoices.filter(
    (invoice) => invoice.invoiceStatus === "paid"
  ).length;
  const voidedCount = invoices.filter(
    (invoice) => invoice.invoiceStatus === "voided"
  ).length;
  const uncollectableCount = invoices.filter(
    (invoice) => invoice.invoiceStatus === "uncollectable"
  ).length;
  return (
    <Box bg="white" p="6" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <StatGroup columnGap={["96px", 0, 0]}>
        <Stat>
          <StatLabel>Total Invoices</StatLabel>
          <StatNumber>{total}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            100%
          </StatHelpText>
        </Stat>

        <Stat>
          <StatLabel>Open</StatLabel>
          <StatNumber>{openCount}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            {handleRounding((openCount / total) * 100)}%
          </StatHelpText>
        </Stat>

        <Stat>
          <StatLabel>Paid</StatLabel>
          <StatNumber>{paidCount}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            {handleRounding((paidCount / total) * 100)}%
          </StatHelpText>
        </Stat>

        <Stat>
          <StatLabel>Voided</StatLabel>
          <StatNumber>{voidedCount}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            {handleRounding((voidedCount / total) * 100)}%
          </StatHelpText>
        </Stat>

        <Stat>
          <StatLabel>Uncollectable</StatLabel>
          <StatNumber>{uncollectableCount}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            {handleRounding((uncollectableCount / total) * 100)}%
          </StatHelpText>
        </Stat>
      </StatGroup>
    </Box>
  );
};
